import axios from 'axios'
import axiosConfig from '@/configs/axios'
import { notification } from 'ant-design-vue'
import { useToken } from '@/common/hooks/useToken'
import store from '@/store/index'
import { getEnvConfig } from './getEnv'

// let loadingInstance
const tokenKey = 'ADMIN_TOKEN'
const { getToken } = useToken(tokenKey)

/**
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */
const handleCode = (data: { message: string; description: string }) => {
  notification['error']({
    message: data.message,
    description: data.description,
  })
}

const handleError = (data: { message: string }) => {
  notification['error']({
    message: '系统异常',
    description: data.message,
  })
}

/**
 * @description axios初始化
 */
const instance = axios.create({
  baseURL: getEnvConfig().baseURL,
  timeout: axiosConfig.requestTimeout,
  responseType: 'blob',
  headers: {
    'Content-Type': axiosConfig.contentType,
  },
})

/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
  (config) => {
    store.dispatch('loading/addLoadingAction')
    const token = getToken()
    if (token) (config as any).headers.Authorization = 'Bearer ' + token
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * @author chuzhixin 1204505056@qq.com
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
  (response) => {
    store.dispatch('loading/subLoadingAction')
    console.log(response, '00')
    return response
  },
  (error) => {
    store.dispatch('loading/subLoadingAction')
    const { response } = error
    if (error.response && error.response.data) {
      handleCode(response.data)
      return Promise.reject(error)
    } else {
      let { message } = error
      if (message === 'Network Error') {
        message = '后端接口连接异常'
        handleError({ message: message })
      }
      if (message.includes('timeout')) {
        message = '后端接口请求超时'
        handleError({ message: message })
      }
      if (message.includes('Request failed with status code')) {
        const code = message.substr(message.length - 3)
        message = '后端接口' + code + '异常'
        handleError({ message: message })
      }
      message.error(message || `后端接口未知异常`)
      return Promise.reject(error)
    }
  }
)

export default instance
