import axios from '@/common/utils/downloadaxios'
import { AxiosRequestConfig } from 'axios'

export const requests = (options: AxiosRequestConfig) => {
  return axios({ method: 'post', ...options } as AxiosRequestConfig)
}

export const API = {
  Projects: {
    Download: 'projects/download',
    project_applies_dow:'project_applies/download',
    project_accepts_dow:'project_accepts/download',
    effecpictureapply_dow:"project_applies/apply_audit_download",
    apply_audit_download:"project_accepts/accept_audit_download",
    supplier_audit_download:"project_applies/supplier_audit_download"
  },
}
