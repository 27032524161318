<template>
  <a-select
    v-model:value="selected"
    show-search
    placeholder="输入选择公司"
    allowClear
    :style="`width:${width};`"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="null"
    :options="items"
    @search="getSearchFun"
  ></a-select>
</template>
<script>
  import { defineComponent, ref, watchEffect, watch, onMounted } from 'vue'
  import { API, requests } from '@/api/apis'
  export default defineComponent({
    props: {
      value: Number,
      placeholder: {
        type: String,
        default: '请选择',
      },
      width: {
        type: String,
        default: '200px',
      },
      keyword: String,
    },
    emits: ['update:value', 'onChange'],
    setup(props, ctx) {
      const items = ref([])
      const selected = ref()
      watch(
        () => items.value,
        () => {
          console.log(222)
          // ctx.emit('update:value', selected.value)
          items.value.forEach((res) => {
            if (res.value == selected.value) {
              ctx.emit('onChange', JSON.parse(res.price_json))
            }
          })
        }
      )
      watch(
        () => selected.value,
        (id) => {
          if (id) {
            ctx.emit('update:value', id)
            items.value.forEach((res) => {
              if (res.value == id) {
                ctx.emit('onChange', JSON.parse(res.price_json))
              }
            })
          } else {
            ctx.emit('update:value', '')
          }
        }
      )
      watchEffect(() => {
        console.log(props.keyword)
        if (props.keyword) {
          getSearchFun(props.keyword)
        }
        if (props.value) {
          selected.value = props.value
        }
      })
      onMounted(() => {
        if (!props.keyword && !props.value) {
          getSearchFun('')
        }
      })
      const getSearchFun = (key) => {
        requests({
          url: API.suppliers.Select,
          data: { keyword: key },
        })
          .then((res) => {
            if (res.data) {
              items.value = res.data
            }
          })
          .catch((err) => {
            console.log(err, 'skk')
          })
      }

      return {
        getSearchFun,
        selected,
        items,
      }
    },
  })
</script>
